import { createSlice } from '@reduxjs/toolkit';
import commonSlice, { GameStatus } from './commonSlice';

const initialState = {
  roundId: 0,
  historyTotal: 0,
  status: GameStatus.UNKNOWN,
  result: null,
};

export const setDiceResult = (a) => diceSlice.actions.setResult(a);
export const setDiceStatus = (a) => diceSlice.actions.setStatus(a);
export const setDiceRound = (a) => diceSlice.actions.setRound(a);

export const diceSlice = createSlice({
  name: 'dice',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setRound: (state, action) => {
      state.roundId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(commonSlice.actions.setStatus, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(commonSlice.actions.setHistory, (state, action) => {
      console.log('redux history', action);
      try {
        const { total, sessions } = action.payload;
        state.historyTotal = total;
        const lastResult = sessions[0];
        state.result = lastResult;
        console.log('Log:lastResult', lastResult);
        if (lastResult.result) {
          state.roundId = state.historyTotal + 1;
        } else {
          state.roundId = state.historyTotal || 1;
        }
      } catch (e) {
        console.error('redux history', e);
      }
    });
  },
});
export default diceSlice;
