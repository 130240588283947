export const DICE_2D = {
  diceFace1: 'assets/dice/2DFace/diceFace_one.svg',
  diceFace2: 'assets/dice/2DFace/diceFace_two.svg',
  diceFace3: 'assets/dice/2DFace/diceFace_three.svg',
  diceFace4: 'assets/dice/2DFace/diceFace_four.svg',
  diceFace5: 'assets/dice/2DFace/diceFace_five.svg',
  diceFace6: 'assets/dice/2DFace/diceFace_six.svg',
  diceFaceEven: 'assets/dice/2DFace/diceFace_even.svg',
  diceFaceOdd: 'assets/dice/2DFace/diceFace_odd.svg',
};

export const ROLL_N_WIN_PNG = 'assets/dice/rollnwin.png';

export const DICE_RESULT = {
  SMALL: 1,
  BIG: 2,
  TRIPLE: 3,
  ODD: 1,
  EVEN: 2,
};
