import { useState } from 'react';
import { DICE_2D, DICE_RESULT } from './config';
import { getDiceNumberResult, getDiceResultText } from './utils';

export const ResultRow = ({
  gameIdx,
  diceResult,
  condense = false,
  isFirst,
}) => {
  const { rolls = [], big_small } = diceResult?.result || {};
  if (isFirst && rolls?.length === 0) {
    return null;
  }
  return (
    <>
      {!condense ? (
        <div className='text-white whitespace-nowrap'>Game {gameIdx}: </div>
      ) : null}
      <div
        className={`text-left whitespace-nowrap min-w-[150px] ${
          big_small === DICE_RESULT.BIG
            ? 'text-[#04A009]'
            : big_small === DICE_RESULT.SMALL
            ? 'text-[#DE173E]'
            : 'text-white'
        } font-semibold`}
      >
        {getDiceResultText(big_small)}
        {getDiceNumberResult(big_small, rolls)}
      </div>
      <ResultDicesIcon diceResult={diceResult} condense={condense} />
    </>
  );
};

const ResultDicesIcon = ({ diceResult, condense }) => {
  const { rolls, odd_even } = diceResult?.result || {};
  const imgToIcon = {
    [DICE_RESULT.ODD]: DICE_2D.diceFaceOdd,
    [DICE_RESULT.EVEN]: DICE_2D.diceFaceEven,
  };
  const [img4th] = useState(imgToIcon[odd_even] || null);
  return (
    <div className='flex flex-none flex-row gap-2 items-center justify-end pr-2'>
      {rolls?.map((number, index) => {
        const icon = DICE_2D[`diceFace${number}`] || null;
        return (
          <img
            key={`${index}`}
            width={condense ? 60 : 30}
            height={condense ? 60 : 30}
            src={icon}
            alt={number}
          />
        );
      })}
      {!condense && img4th && (
        <img width={30} height={30} src={img4th} alt={odd_even} />
      )}
    </div>
  );
};
