import { DICE_RESULT } from './config';

export const getDiceNumberResult = (big_small = 0, arr = [0, 0, 0]) => {
  //this will return the dice number if all 3 number is the same
  //return the total sum of the 3 number if its not triple
  if (big_small === DICE_RESULT.TRIPLE) {
    return `(Number: ${arr[0]})`;
  } else {
    if (arr.length !== 3) {
      return 0;
    } else {
      return `(Total: ${arr[0] + arr[1] + arr[2]})`;
    }
  }
};

export const getDiceResultText = (big_small) => {
  if (big_small === DICE_RESULT.SMALL) {
    return 'Small';
  } else if (big_small === DICE_RESULT.BIG) {
    return 'Big';
  } else if (big_small === DICE_RESULT.TRIPLE) {
    return 'Triple';
  } else {
    return '';
  }
};
