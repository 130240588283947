import { GAME_ID } from '../../const/DiceConfig';
import { ResultRow } from './ResultRow';
import { ROLL_N_WIN_PNG } from './config';

export const DiceHistory = ({ total, history }) => {
  console.log('Log:history', history);
  return (
    <>
      <div className='flex flex-grow flex-col items-stretch gap-5 py-10 pl-4 pr-24 overflow-hidden h-full'>
        <img
          src={ROLL_N_WIN_PNG}
          alt='Roll & Win'
          className='flex-none w-fit h-fit mix-blend-luminosity p-2'
        />
        <div className='flex flex-grow flex-col ring-[10px] ring-[#CBBCB4] rounded-xl bg-gradient-to-b from-[#191A1D] to-[#11151D] p-5'>
          <p className='flex-none text-white text-[22px] font-semibold'>
            Latest Game Results
          </p>
          <div className='flex-[1_0_1px] grid grid-cols-[auto_minmax(0,_1fr)_auto] grid-flow-row content-start gap-2 overflow-y-auto'>
            {history
              ?.filter((d) => {
                return d.stream_game_id == GAME_ID.DICE;
              })
              .map((result, idx) => {
                return (
                  <ResultRow
                    isFirst={idx === 0}
                    key={result.session_id}
                    diceResult={result}
                    gameIdx={total - idx}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const sampleHistory = {
  code: 0,
  data: {
    sessions: [
      { stream_game_id: 1, session_id: 9, status: 1 },
      {
        stream_game_id: 1,
        session_id: 8,
        result: { rolls: [1, 1, 1], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 7,
        result: { rolls: [3, 4, 4], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 6,
        result: { rolls: [5, 5, 3], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 5,
        result: { rolls: [2, 4, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 4,
        result: { rolls: [1, 5, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 3,
        result: { rolls: [5, 4, 6], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 2,
        result: { rolls: [4, 4, 6], big_small: 2, odd_even: 2 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 1,
        result: { rolls: [1, 2, 2], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 7,
        result: { rolls: [3, 4, 4], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 6,
        result: { rolls: [5, 5, 3], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 5,
        result: { rolls: [2, 4, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 4,
        result: { rolls: [1, 5, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 3,
        result: { rolls: [5, 4, 6], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 2,
        result: { rolls: [4, 4, 6], big_small: 2, odd_even: 2 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 1,
        result: { rolls: [1, 2, 2], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 7,
        result: { rolls: [3, 4, 4], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 6,
        result: { rolls: [5, 5, 3], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 5,
        result: { rolls: [2, 4, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 4,
        result: { rolls: [1, 5, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 3,
        result: { rolls: [5, 4, 6], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 2,
        result: { rolls: [4, 4, 6], big_small: 2, odd_even: 2 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 1,
        result: { rolls: [1, 2, 2], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 7,
        result: { rolls: [3, 4, 4], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 6,
        result: { rolls: [5, 5, 3], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 5,
        result: { rolls: [2, 4, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 4,
        result: { rolls: [1, 5, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 3,
        result: { rolls: [5, 4, 6], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 2,
        result: { rolls: [4, 4, 6], big_small: 2, odd_even: 2 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 1,
        result: { rolls: [1, 2, 2], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 7,
        result: { rolls: [3, 4, 4], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 6,
        result: { rolls: [5, 5, 3], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 5,
        result: { rolls: [2, 4, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 4,
        result: { rolls: [1, 5, 3], big_small: 1, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 3,
        result: { rolls: [5, 4, 6], big_small: 2, odd_even: 1 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 2,
        result: { rolls: [4, 4, 6], big_small: 2, odd_even: 2 },
        status: 3,
      },
      {
        stream_game_id: 1,
        session_id: 1,
        result: { rolls: [1, 2, 2], big_small: 1, odd_even: 1 },
        status: 3,
      },
    ],
  },
  msg: 'Created successfully',
};
