import { createSlice } from '@reduxjs/toolkit';
import { GAME_ID } from '../const/DiceConfig';
export const GameStatus = {
  // below are from API
  UNKNOWN: 0,
  OPEN: 1,
  CLOSED: 2,
  COMPLETED: 3,
  SETTLED: 4,
  // below are customised
  ROLLING: 1000,
  ROLLINGEND: 1001,
};

const initialState = {
  gameId: GAME_ID.DICE,
  history: [],
  status: GameStatus.UNKNOWN,
};

// export const appendSessionHistory = (a) => commonSlice.actions.appendHistory(a);
export const setSessionHistory = (a) => commonSlice.actions.setHistory(a);
export const setGameStatus = (a) => commonSlice.actions.setStatus(a);
export const setCurrentGameId = (a) => commonSlice.actions.setCurrentGameId(a);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload.sessions;
    },
    // appendHistory: (state, action) => {
    //   state.history = [...state.history, ...action.payload.sessions];
    // },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setCurrentGameId: (state, action) => {
      state.gameId = action.payload;
    },
  },
});
export default commonSlice;
