import { useCallback } from 'react';
import { GameStatus } from '../../store/commonSlice';

const BtnStyleInactive = 'opacity-50';
const BtnStyleActive = '';
const BtnStyleHover = 'hover:-translate-y-1 hover:scale-[1.05]';

export const BtnStatus = {
  Active: 'Active',
  InActive: 'InActive',
};
const BtnStyle = {
  [BtnStatus.Active]: BtnStyleActive,
  [BtnStatus.InActive]: BtnStyleInactive,
};

export const DiceBtns = ({ status, action = {} }) => {
  const { onOpen, onClose, onRoll } = action;
  const getOpenBtnStatus = useCallback(() => {
    return [
      GameStatus.UNKNOWN,
      GameStatus.SETTLED,
      GameStatus.COMPLETED,
      GameStatus.ROLLINGEND,
    ].includes(status)
      ? BtnStatus.Active
      : BtnStatus.InActive;
  }, [status]);

  const getCloseBtnStatus = useCallback(() => {
    return [GameStatus.OPEN].includes(status)
      ? BtnStatus.Active
      : BtnStatus.InActive;
  }, [status]);

  const getRollBtnStatus = useCallback(() => {
    return [GameStatus.CLOSED].includes(status)
      ? BtnStatus.Active
      : BtnStatus.InActive;
  }, [status]);

  return (
    <div className='flex flex-1 flex-row my-5 gap-5 justify-between'>
      <div className='flex-1' />
      <DiceControlBtn
        img='/assets/dice/buttons/start.png'
        status={getOpenBtnStatus()}
        onPress={onOpen}
      ></DiceControlBtn>
      <DiceControlBtn
        img='/assets/dice/buttons/close.png'
        status={getCloseBtnStatus()}
        onPress={onClose}
      ></DiceControlBtn>
      <DiceControlBtn
        img='/assets/dice/buttons/roll.png'
        status={getRollBtnStatus()}
        onPress={onRoll}
      ></DiceControlBtn>
      <div className='flex-1' />
    </div>
  );
};

const DiceControlBtn = ({ img, status, onPress }) => {
  return (
    <button
      disabled={status !== BtnStatus.Active}
      onClick={onPress}
      className={`${
        status === BtnStatus.Active
          ? BtnStyle.Active
          : status === BtnStatus.InActive
          ? BtnStyle.InActive
          : ''
      } ${status === BtnStatus.Active && BtnStyleHover} rounded flex-[2] py-2`}
    >
      <img src={img} />
    </button>
  );
};
