import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ResultRow } from '../DiceHistory/ResultRow';
import { GameStatus } from '../../store/commonSlice';

export const DiceLabels = () => {
  const diceStatus = useSelector((s) => s.dice.status);
  const diceResult = useSelector((s) => s.dice.result);
  const historyTotal = useSelector((s) => s.dice.historyTotal);
  const roundId = useSelector((s) => s.dice.roundId);
  const [label, setLabel] = useState('');

  useEffect(() => {
    const text =
      {
        [GameStatus.OPEN]: 'Open For Bet',
        [GameStatus.CLOSED]: 'Stop',
        [GameStatus.ROLLING]: 'Rolling',
      }[diceStatus] || null;
    setLabel(text);
  }, [diceStatus, diceResult]);
  console.log('Log:diceStatus', diceStatus);
  return (
    <div className='absolute inset-x-0 top-[5rem] flex flex-row justify-center'>
      {label ? (
        <>
          <div className='fixed top-12 left-12 text-[40px] text-white font-semibold'>
            Game {roundId}
          </div>
          <div className='text-[45px] text-white font-semibold bg-[#191A1D8F] py-2 px-5 rounded-xl'>
            {label}
          </div>
        </>
      ) : null}
      {diceResult && GameStatus.ROLLINGEND === diceStatus ? (
        <div className='flex flex-col items-center'>
          <div className='text-white text-[55px] font-semibold'>
            Result For Game {historyTotal}
          </div>
          <div className='flex flex-row items-center bg-[#191A1D8F] p-5 rounded-xl text-[45px] gap-5'>
            <ResultRow diceResult={diceResult} condense />
          </div>
        </div>
      ) : null}
    </div>
  );
};
